import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16')
];

export const server_loads = [];

export const dictionary = {
		"/": [3],
		"/dashboard": [4,[2]],
		"/dashboard/login": [~5],
		"/dashboard/settings": [6,[2]],
		"/dashboard/settings/profile": [7,[2]],
		"/dashboard/settings/users": [8,[2]],
		"/dashboard/settings/users/[slug]": [9,[2]],
		"/dashboard/status-cached": [11,[2]],
		"/dashboard/status": [10,[2]],
		"/dashboard/tools/brand/[slug]": [12,[2]],
		"/dashboard/tools/campaign/[slug]": [13,[2]],
		"/form": [14],
		"/form/success": [16],
		"/form/[slug]": [~15]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';